<template>
  <body ontouchstart="">
    <div style="text-align: center;">
      <van-image style="margin: 25% 35% 3% 35%;" width="5rem" height="5rem" fit="cover" :src="newImg" />
      <span style="margin: auto 35% auto 35%;">支付成功!</span>
      <van-button type="primary" style="margin: 15% 5% auto 5% ; width: 90%; height: 40%;" color="#57c4b7" round size="large"
        to="/PayL">返回</van-button>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>@拓实科技</span></div>
    </div>
  </body>
</template>

<script>
  export default {
    data() {
      return {
        newImg: require('../../assets/PaySuccess.png'),
      }
    }
  }
</script>

<style>
</style>
